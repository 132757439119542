<template>
  <v-app>
    <v-app-bar color="white" fixed height="76">
      <div class="d-flex tw-w-full justify-space-between">
        <enum-with-word-icon />
        <profile-drop-down />
      </div>
    </v-app-bar>
    <div>
      <div class="profile--big--wrapper md:tw-ml-24 tw-mb-12 tw-flex md:tw-block">
        <v-btn v-if="$route.query.from === 'dashboard'" @click="back('learn')" text class="backFont text-capitalize">
          <vue-feather style="width: 15px" class="tw-mr-2" type="arrow-left" />
          Back to Learning
        </v-btn>
        <v-btn v-if="$route.query.from !== 'dashboard' && $route.query.from !== 'ops'" @click="back('job')" text class="backFont text-capitalize">
          <vue-feather style="width: 15px" class="tw-mr-2" type="arrow-left" />
          Back to Jobs
        </v-btn>
        <v-btn v-if="$route.query.email" @click="back('ops')" text class="backFont text-capitalize">
          <vue-feather style="width: 15px" class="tw-mr-2" type="arrow-left" />
          Back to Ops
        </v-btn>
      </div>

      <div class="tw-flex mx-auto profile--wrapper tw-w-full px-5 tw-flex-col">
        <Loader v-if="this.loading" :loading="this.loading"/>
        <div v-if="!this.loading" class="card-wrapper tw-flex-col">
          <div class="tw-flex tw-flex-col  lg:tw-px-20 tw-pl-3   tw-py-5 tw-w-full tw-items-center">
            <div class="tw-flex tw-w-full tw-flex-row tw-justify-between tw-items-center">
              <div class="tw-flex tw-w-auto" @click="openExplorer">
                <v-progress-circular
                    :indeterminate="imageUploading"
                    :size="90"
                    :style="{stroke:showCircular}"
                    color="#008EEF"
                >
                  <v-badge
                      avatar
                      bordered
                      bottom
                      overlap>
                    <template v-slot:badge>
                      <div v-if="isMy" class="edit-pen tw-cursor-pointer">
                        <input
                            id="upload-input"
                            ref="upload_input"
                            accept="img/*"
                            style="display: none"
                            type="file"
                            @change="uploadImage"
                        />
                        <vue-feather style="width: 13px; height: 13px; color: #9CABB5" type="edit-2"/>
                      </div>
                    </template>
                    <v-avatar height="72" width="72">
                      <img v-if="personalProfile.image" :src="image" alt="image" height="72" width="72">
                      <label v-else class="text-uppercase text-avatar">{{ avatar }}</label>
                    </v-avatar>
                  </v-badge>
                </v-progress-circular>

              </div>
              <div v-if="!$route.query.email">
                <base-button v-if="isMy" button-text="Edit Bio" outlined width="auto" @click="handleEditProfileModal"/>
              </div>
            </div>
            <div class="tw-flex lg:tw-flex-row tw-w-full tw-flex-col tw-mt-6 tw-items-start pro">
              <div class="tw-flex tw-flex-col lg:tw-w-1/2 tw-w-full">
                <h6 class="profile-name text-capitalize">{{ personalProfile.first_name }}
                  {{ personalProfile.last_name }}</h6>
                <div class="tw-flex tw-flex-row tw-mt-2 tw-items-center">
                  <div style="background: #0BCA7A; height: 8px;width: 8px; border-radius: 50%"/>
                  <label
                      class="profile-status tw-ml-2 text-capitalize">{{ personalProfile.job_search_stage.split('_').join(' ') }}</label>
                </div>
                <div class="tw-flex tw-flex-row tw-mt-2 tw-items-center">
                  <vue-feather style="color:#475661; width: 15px; height: 18px;" type="map-pin"/>
                  <label class="profile-status tw-ml-2">{{ personalProfile.location }}</label>
                </div>
              </div>
              <div
                  class="tw-flex lg:tw-w-1/2 tw-w-full lg:tw-justify-end tw-justify-between tw-flex-row tw-mt-5 pro-follow ">
                <div :class="[isMy===true ? ' lg:tw-mr-4 tw-w-1/2 lg:tw-w-auto':'tw-w-1/2 lg:tw-w-auto']"
                     class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center ">
                  <label class="profile-count lg:tw-mx-2 tw-mx-0">0</label> <label class="profile-status">Followers</label>
                </div>
                <div :class="[isMy===true ? ' lg:tw-mr-4 tw-w-1/2 lg:tw-w-auto':'tw-w-1/2 lg:tw-w-auto']"
                     class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center ">
                  <label class="profile-count lg:tw-mx-2 tw-mx-0">{{ personalProfile.following }}</label> <label
                    class="profile-status">Following</label>
                </div>
<!--                <div v-if="isMy" class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center">-->
<!--                  <label class="profile-count lg:tw-mx-2 tw-mx-0">{{ personalProfile.application }}</label> <label-->
<!--                    class="profile-status">Applications</label>-->
<!--                </div>-->
              </div>
            </div>
          </div>
          <v-divider/>
          <div class="tw-flex tw-flex-col lg:tw-flex-row  lg:tw-px-20 tw-pl-3 tw-w-full">
            <div class="tw-flex lg:tw-w-4/6 tw-w-full tw-flex-col about-me lg:tw-py-5 tw-py-10 ">
              <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-flex-row lg:tw-pr-10 tw-pb-3 ">
                <label class="about-me-text">About Me</label>
                <label v-if="!$route.query.email" class="edit-btn tw-cursor-pointer" @click="handleEditAboutMe()">Edit</label>
              </div>
              <label class="profile-content">
                {{ personalProfile.about_me }}
              </label>
            </div>
            <v-divider class="tw-flex lg:tw-hidden"/>
            <div class="tw-flex lg:tw-w-1/3 tw-w-full tw-flex-col">
              <v-progress-linear
                  :indeterminate="getLinksLoading"
                  background-color="transparent"
                  color="#008EEF"
                  height="3"
                  rounded
              ></v-progress-linear>
              <div class="tw-flex  tw-w-full tw-flex-col lg:tw-py-5 lg:tw-pl-10 tw-py-10 scroll">
                <div class="tw-flex tw-w-full tw-justify-between tw-flex-row  tw-items-center ">
                  <label class="about-me-text">Links</label>
                  <label v-if="!$route.query.email" class="edit-btn tw-cursor-pointer" @click="handleEditLinks()">Add</label>
                </div>
                <div class="tw-flex tw-flex-row lg:tw-flex-col tw-w-full">
                  <div v-if="!links.length" class="tw-flex tw-w-full">
                    <label class="linkedin">No Links yet</label>
                  </div>
                  <div v-else class="tw-flex tw-w-full tw-flex-col">
                    <div v-for="(link,index) in links" :key="index"
                         class="tw-flex tw-w-full tw-justify-between tw-items-center tw-mt-5">
                      <div class="tw-flex tw-flex-row tw-items-start  tw-w-1/2 lg:tw-w-auto">
                        <img v-if="link.title=== 'Linkedin' || link.title=== 'LinkedIn'" alt="linkedin" class="tw-cursor-pointer"
                             height="20" src="@/assets/images/linkedin.svg" width="20"
                             @click="openLink(link.link)"/>
                        <vue-feather v-else class="tw-cursor-pointer" height="20" type="link-2" width="20"
                                     @click="openLink(link.link)"/>
                        <label class="linkedin tw-ml-3 tw-mr-2 text-capitalize tw-cursor-pointer"
                               @click="openLink(link.link)">{{ link.title }}</label>
                      </div>
                      <label v-if="!$route.query.email" class="edit-btn tw-cursor-pointer" @click="handleEditLinks(link.id)">Edit</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <v-divider/>
          <v-progress-linear
              :indeterminate="this.educationLoading"
              background-color="transparent"
              color="#008EEF"
              height="3"
              rounded
          ></v-progress-linear>
          <div class="tw-flex tw-flex-col   lg:tw-px-20 lg:tw-py-5 tw-pl-3  tw-py-10 tw-w-full">
            <div class="tw-flex tw-w-full tw-justify-between tw-flex-row  tw-items-center tw-my-5">
              <h6 class="profile-name">Education</h6>
              <label v-if="!$route.query.email" class="edit-btn tw-cursor-pointer" @click="handleEditEducation()">Add</label>
            </div>
            <div v-if="educations.length === 0">
              <label class="school-name">No education yet</label>
            </div>
            <div v-else class="tw-flex tw-w-full tw-flex-col">
              <div v-for="(school,index) in educations" :key="index"
                   class="tw-flex tw-w-full tw-flex-row tw-py-5 tw-items-start">
                <div class="tw-flex tw-w-3/12 lg:tw-w-1/12 tw-mr-5">
                  <img :src="school.logo" alt="company logo" height="64" width="64"/>
                </div>
                <div class="tw-flex tw-w-9/12 lg:tw-w-11/12 tw-flex-col">
                  <div class="tw-flex tw-w-full tw-justify-between tw-items-start">
                    <label class="school-name">{{ school.school_or_training }}</label>
                    <v-menu offset-y v-if="!$route.query.email">
                      <template v-slot:activator="{ on, attrs }">
                        <vue-feather v-bind="attrs" v-on="on" class="tw-cursor-pointer" type="more-horizontal"/>
                      </template>
                      <v-list>
                        <v-list-item
                            v-for="(item, index) in items" :key="index">
                          <v-list-item-title class="tw-cursor-pointer"
                                             @click="handleSelectedEducationAction(school.id,item)">{{ item }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                  <label class="title-course">{{ school.degree_or_certificate }}</label>
                  <label class="school-bio">{{ school.description }}</label>
                  <label v-if="school.end_date" class="start-end-date">{{ processedDay(school.start_date) }} -
                    {{ processedDay(school.end_date) }}</label>
                  <label v-else class="start-end-date">{{ processedDay(school.start_date) }} - Till date</label>
                </div>
              </div>
            </div>

          </div>
          <v-divider/>
         <VerifiedSkills v-if="!$route.query.email" />
          <v-divider/>
        </div>

        <div class="card-wrapper tw-flex-col tw-mt-24 md:tw-mt-28">
          <special-profile id="special"/>
          <v-divider/>
<!--          <div class="tw-flex tw-w-full tw-py-10 tw-flex-col lg:tw-px-20 tw-pl-3">-->
<!--            <h6 class="profile-name">Interests</h6>-->
<!--            <div v-for="(interest,index) in interests" :key="index"-->
<!--                 class="tw-flex tw-flex-row tw-w-full tw-my-5 tw-items-center">-->
<!--              <img :src="interest.company_logo" alt="logo" height="64" width="64"/>-->
<!--              <div class="tw-flex tw-flex-col tw-ml-5">-->
<!--                <label class="school-name tw-mb-1">{{ interest.company_name }}</label>-->
<!--                <label class="title-course">{{ interest.industry }}</label>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <v-divider/>-->
<!--          <div :class="[showMore ? 'more':'less']" class="tw-flex tw-w-full tw-py-10 tw-flex-col lg:tw-px-20 tw-pl-3">-->
<!--            <div class="tw-flex tw-w-full tw-justify-between tw-flex-row  tw-items-center tw-my-5">-->
<!--              <h6 class="profile-name">Job Alerts</h6>-->
<!--            </div>-->
<!--            <div v-for="(v, k,index) in jobAlerts" :key="index"-->
<!--                 class="tw-flex tw-w-full tw-justify-between tw-items-center tw-my-5">-->

<!--              <div v-if="index <= getIndex" class="tw-flex tw-flex-col">-->
<!--                <label class="school-name tw-mb-1">{{ k }}</label>-->
<!--                <label class="title-course">{{ v }}</label>-->
<!--              </div>-->
<!--              <vue-feather v-if="index <= getIndex" class="tw-cursor-pointer" style="width: 20px; height: 20px"-->
<!--                           type="trash-2" @click="deleteJobAlert(k)"/>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div v-if="jobAlerts.length > 2" class="tw-py-5">-->
<!--            <label class=" tw-py-10 lg:tw-px-20 tw-pl-3 title-course tw-cursor-pointer"-->
<!--                   @click="handleShowMore">{{ showMore ? 'View less' : 'View more' }}</label>-->
<!--          </div>-->
          <profile-modal :data="modalData" :dialog="dialog"
                         :section="section" @allLinks="getAllLinks" @changeSection="changeSection"
                         @close="closeModal" @refresh="refreshEducation"/>
        </div>
      </div>
      </div>
  </v-app>
</template>

<script>
// import BaseButton from "../../reusables/BaseButton";
import logoForEducation from "@/assets/images/company.svg"
import SpecialProfile from "./SpecialProfile";
// import ProfileModal from "./ProfileModal";
import {getUserInterest} from "@/services/api/AuthApiService";
import Loader from "../../../Loader";
import {mapGetters} from "vuex"
import moment from "moment";
import {uploadMediaFile} from "@/services/mediaFileUploader";
import profileImage from "@/mixins/profile/profileImage";
import EnumWithWordIcon from "@/components/icons/EnumWithWordIcon";
import ProfileDropDown from "@/components/reusables/ProfileDropDown";
import VerifiedSkills from "./VerifiedSkills";
import ProfileModal from "@/components/dashboard/components/ProfileModal";
import BaseButton from "@/components/reusables/BaseButton";



export default {
  name: "Profile",
  components: {BaseButton, ProfileModal, VerifiedSkills, ProfileDropDown, EnumWithWordIcon, Loader, SpecialProfile,},
  mixins: [profileImage],
  data() {
    return {
      isMy: true,
      items: ["Edit", "Delete"],
      educations: [],
      interests: [],
      jobAlerts: [],
      showMore: false,
      dialog: false,
      section: "",
      loading: false,
      personalProfile: {},
      modalData: {},
      links: [],
      imageUploading: false,
      getLinksLoading: false,
    }
  },
  computed: {
    ...mapGetters('personalProfile', ['getEducations', 'educationLoading', 'getLinks', 'getJobAlerts']),
    getIndex() {
      let highestIndex = 1
      if (this.showMore) {
        return highestIndex = this.jobAlerts.length - 1
      } else {
        return highestIndex
      }
    },
    showCircular() {
      if (this.imageUploading) {
        return 'rgba(158, 158, 158, 0.4)'
      }
      return ''
    },
    avatar() {
      if (!this.personalProfile.first_name || !this.personalProfile.last_name) {
        return null
      } else {
        return this.personalProfile.first_name.charAt(0).toUpperCase() + this.personalProfile.last_name.charAt(0).toUpperCase()
      }

    },
  },

  methods: {
    handleShowMore() {
      this.showMore = !this.showMore
    },
    back (type) {
      const learnerRoute = sessionStorage.getItem('learnerRoute')
      console.log(learnerRoute)
      if(type==='learn'){
        window.location.href = `${process.env.VUE_APP_LEARNER_FRONTEND_URL}/dashboard`
      } else if(type === 'ops'){
        window.location.href = `${process.env.VUE_APP_ENUM_OPS}/talent/pool`
      }
      else {
        this.$router.push({name: 'ApplicantDashboard'})      }
    },
    handleEditProfileModal() {
      this.dialog = !this.dialog;
      this.section = "Edit Profile";
      this.modalData = this.personalProfile
    },
    handleEditLinks(id) {
      if (id) {
        this.modalData = this.links.find(link => link.id === id)
        this.dialog = !this.dialog;
        this.section = "Edit Link"
      } else {
        this.modalData = {}
        this.dialog = !this.dialog;
        this.section = "Add Link"
      }
    },
    handleEditEducation(id) {
      if (id) {
        this.modalData = this.educations.find(edu => edu.id === id)
        this.dialog = !this.dialog;
        this.section = "Education"
      } else {
        this.modalData = {}
        this.dialog = !this.dialog;
        this.section = "Education"
      }
    },
    async handleSelectedEducationAction(id, item) {
      if (item === 'Delete') {
        await this.$store.dispatch('personalProfile/deleteEducation', id)
        await this.$store.dispatch('personalProfile/getAllEducations')
        this.populateData(this.getEducations)
      } else if (item=== 'Edit'){
        this.modalData= this.educations.find(edu => edu.id === id)
        this.dialog = !this.dialog
        this.section = "Education"
      }
      else {
        this.modalData ={}
        this.dialog =!this.dialog
        this.section = "Education"
      }
    },


    handleEditAboutMe() {
      this.dialog = !this.dialog;
      this.section = "About Me"
      this.modalData = this.personalProfile
    },
    changeSection(section) {
      this.section = section
    },
    closeModal() {
      this.dialog = false;
      this.section = ""
    },
    async getAllLinks() {
      this.getLinksLoading = true
      await this.$store.dispatch('personalProfile/getAllLinks')
      this.links = this.getLinks
      this.getLinksLoading = false
    },
    async refreshEducation() {
      this.educationLoading = true
      await this.$store.dispatch('personalProfile/getAllEducations')
      this.populateData(this.getEducations)
      this.educationLoading = false
    },

    uploadImage(event) {
      let file;
      if (event.type === "drop") {
        file = event.dataTransfer.files[0];
      } else {
        this.isDraggedOver = true;
        file = event.target.files[0];
      }
      if (file) {
        this.updateProfileImage(file)
      }
    },
    openExplorer() {
      this.$refs.upload_input.click();
    },
    populateData(data) {
      this.educations = data
      this.educations.forEach(function (education) {
        education.logo = logoForEducation
      })
    },
    processedDay(date_created) {
      return moment(date_created).format("MMM YYYY");
    },
    openLink(link) {
      window.open(link, '_blank')
    },
    async updateProfileImage(file) {
      this.imageUploading = true
      const exts = ['png', 'jpeg', 'jpg', 'tiff']
      let splitFile = file.name.split(".")
      let fileExtension = splitFile[splitFile.length - 1]
      console.log(fileExtension)
      if (!exts.includes(fileExtension)) {
        this.$displaySnackbar({message: 'Only accepts Images', success: false})
        file = ""
        this.imageUploading = false
        return null
      } else {
        const {url, key} = await uploadMediaFile(file, 'users_profile')
        console.log(url)
        this.personalProfile.image = JSON.stringify({url, key})
        this.$store.dispatch('applicantProfile/updateUserProfile', {image: this.personalProfile.image}).then(() => {
          this.imageUploading = false
        })
            .catch(err => {
              console.log(err)
              let errorKey = Object.keys(err.response.data)
              this.$displaySnackbar({
                message: err.response.data[errorKey[0]],
                success: false
              })
            }).finally(() => this.imageUploading = false)
      }
    },
    async deleteJobAlert(key) {
      const deleteObject = {}
      deleteObject.job_alert = key
      await this.$store.dispatch('personalProfile/deleteJobAlert', deleteObject)
      this.jobAlerts = this.getJobAlerts
    },
    async getUserInterests() {
      let userData = JSON.parse(sessionStorage.getItem('userData'))
      if (!userData) userData = JSON.parse(sessionStorage.getItem('userProfile'))
      await getUserInterest(userData.email).then(res => {
        this.interests = res.data
      })
          .catch(err => {
            let errorKey = Object.keys(err.response.data)
            this.$displaySnackbar({
              message: err.response.data[errorKey[0]],
              success: false
            })
          })
    }

  },
  async created() {
    this.loading = true
    if (this.$route.query.token) {
      sessionStorage.setItem('userToken', this.$route.query.token)
    }
    if(this.$route.query.email){
      await this.$store.dispatch('applicantProfile/adminGetApplicant', this.$route.query.email)
      await this.$store.dispatch('personalProfile/adminGetAllEducations', this.$route.query.email)
      this.personalProfile = this.getApplicantProfile
    }else {
      await this.$store.dispatch('applicantProfile/getApplicant')
      await this.$store.dispatch('personalProfile/getUserJobAlerts')
      this.jobAlerts = this.getJobAlerts
      await this.getUserInterests()
      this.personalProfile = this.getApplicantProfile
      await this.$store.dispatch('personalProfile/getAllEducations')
      await this.getAllLinks()
      this.populateData(this.getEducations)

    }
    this.loading = false
  },

}
</script>

<style lang="scss" scoped>
.card-wrapper {
  width: 100%;
  border: 2px solid #F6F7F7;
  @media screen and (max-width: 1024px) {
    border: none;
  }
}

.pro {
  @media screen and (width: 1024px) {
    flex-direction: column;
  }
}

.pro-follow {
  @media screen and (width: 1024px) {
    justify-content: unset;
  }
}

.about-me {
  border-right: 2px solid #F6F7F7;
  @media screen and (max-width: 1024px) {
    border-right: none;
  }
}

.about-me-text {
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #1E323F;
}

.linkedin {
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #475661;
}
.backFont{
  color: #1E323F;
  background: rgba(251, 251, 251, 0.6) !important;
  border-radius: 6px !important;
}
.edit-btn {
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #008EEF;
  text-transform: none;
}

.edit-pen {
  background: #FFFFFF;
  border: 1px solid #FBFBFB;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgba(0, 85, 142, 0.14);
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 1px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
}

.profile-name {
  font-family: IBM Plex Serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #1E323F;
}

.profile-status {
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #475661;
}

.profile-count {
  font-family: IBM Plex Serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #475661;
  @media screen and (max-width: 1024px) {
    font-size: 24px;
    line-height: 31px
  }
}

.school-name {
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #475661;
}

.title-course {
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #475661;
}

.school-bio {
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #9CABB5;
  width: 80%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.start-end-date {
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}

.profile-content {
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #475661;
  width: 80%;
  @media screen and (max-width: 1024px){
    width: 100%;
    word-break: break-word;
  }
}

.less {
  max-height: 10rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.less::-webkit-scrollbar {
  background: transparent !important;
  width: 3px;
  height: 5px;
}

.less::-webkit-scrollbar-thumb {
  background: transparent !important;
  max-height: 15px !important;
  border-radius: 6px;
}

.more {
  max-height: max-content;
}

.social {
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  color: #475661;
}

::v-deep .v-badge--avatar .v-badge__badge {
  background-color: #EEF8FF !important;
  border: none !important;
  margin-top: -10px !important;
  margin-left: -8px !important;
  height: auto !important;
  width: auto !important;
  cursor: pointer !important;
}

::v-deep .v-badge__badge {
  border-radius: 50% !important;
}

::v-deep .theme--light.v-badge .v-badge__badge::after {
  border: none !important;
}

::v-deep .v-badge {
  min-width: 72px;
  min-height: 72px;
  background-color: #EEF8FF;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

::v-deep .theme--light.v-divider {
  border-color: #F6F7F7 !important;;
}

.text-avatar {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #008EEF;
}

.scroll {
  max-height: 20vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 3px;
  height: 5px;
}

.scroll::-webkit-scrollbar-thumb {
  background: transparent !important;
  max-height: 15px !important;
  border-radius: 6px;
}

::v-deep .v-progress-circular__underlay {
  stroke: none !important;
}

.profile--wrapper {
  max-width: 1100px;
  margin-bottom: 20px;
}

.profile--big--wrapper {
  margin-top: 120px;
}
.verified-text {
  width: 300px;
  height: 48px;

  /* Body/Body 2 */
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  /* or 171% */
  display: flex;
  align-items: center;
  text-align: center;

  /* Enum-Grey/2 */
  color: #475661;
}

</style>
