<template>
  <div class="tw-flex tw-w-full tw-flex-col ">
    <v-progress-linear
        color="#008EEF"
        background-color="transparent"
        :indeterminate="workExperienceLoading"
        rounded
        height="3"
    ></v-progress-linear>
    <div v-if="!this.getSpecialProfiles.length" class="tw-flex tw-w-full tw-justify-center tw-items-center lg:tw-py-20  tw-py-10 tw-flex-col">
      <label class="create-profile tw-mb-5">Specialized Profile</label>
      <label v-if="$route.query.email" class="create-profile-desc tw-mb-8">
        No customized profile created by user
      </label>
      <label v-else class="create-profile-desc tw-mb-8">
        Stand out from other candidates during job applications by customizing your profile to fit a specialized role.
      </label>
      <base-button v-if="!$route.query.email" button-text="Create Profile" @click="$router.push({name:'CreateSpecializeProfile',params:{step:'1'}})" outlined width="auto"/>
    </div>

    <div v-if="this.getSpecialProfiles.length" class="tw-flex tw-w-full tw-py-10 tw-flex-col lg:tw-flex-row lg:tw-px-20 tw-pl-3">
      <div class="tw-flex lg:tw-w-2/6 tw-w-full tw-flex-col">
        <div class="tw-flex tw-justify-between tw-w-full">
          <h6 class="all-profile">ALL PROFILES</h6>
          <label v-if="!$route.query.email" class="tw-flex lg:tw-hidden edit-btn tw-cursor-pointer" @click="$router.push({name:'CreateSpecializeProfile',params:{step:'1'}})">CREATE NEW</label>
        </div>
        <div class=" tw-flex lg:tw-hidden tw-my-5">
          <v-combobox :items="specializedProfileTitles" v-model="selectedProfileTitle"
                      outlined hide-details @change="handleSelectedProfileTitle(selectedProfileTitle)"  />
        </div>
        <div class=" tw-hidden lg:tw-flex profile-card tw-mt-5 tw-px-3 tw-flex-col">
          <div v-for="(profile,index) in specializedProfiles" :key="index" class="tw-flex tw-w-full tw-flex-col">
            <div class="tw-flex tw-flex-row tw-w-full tw-justify-between tw-items-center tw-py-5 tw-cursor-pointer"
                 @click="handleSelectedProfileTitle(profile.job_role)">
                <h6 :class="profile.job_role === selectedProfileTitle? 'create-special-selected': 'create-special-default'">
                  {{profile.job_role}}</h6> <vue-feather type="arrow-right" style="width: 24px; height: 24px"
               :style="{color:profile.job_role=== selectedProfileTitle ? '#008EEF': '#9CABB5'}"/>
            </div>
            <v-divider />
          </div>
          <div v-if="!$route.query.email" class="my-2 tw-flex tw-flex-row tw-w-full tw-justify-between tw-items-center tw-pt-3 tw-cursor-pointer"
            @click="$router.push({name:'CreateSpecializeProfile',params:{step:'1'}})">
            <h6 class="create-special-default">Create Specialized Profile</h6>
            <vue-feather type="plus" style="width: 24px; height: 24px; color: #9CABB5"/>
          </div>
        </div>
      </div>
      <div class="tw-flex lg:tw-w-4/6 tw-w-full tw-flex-col special-body ">
        <div class="tw-flex tw-w-full tw-justify-between tw-items-center">
          <label class="display-profile-title">{{selectedProfile.job_role}}</label>
          <label v-if="!$route.query.email" class="edit-btn tw-cursor-pointer" @click="handleUpdateSpecialProfile">Edit</label>
        </div>
        <label class="display-profile-desc tw-py-3">{{selectedProfile.description}}</label>
        <div class="">
          <div class="scroll-y">
            <div v-for="(tab,index) in tabs" :key="index" @click="handleSelectedTab(tab)"
                 class="tw-flex tw-justify-center tw-items-center tw-p-2 " :id="tab"  :class="tab === selectedTab ? 'selected-tab':'tab'"
            ><a :href="link">{{tab}}</a></div>
          </div>
        </div>

        <div id="experience" class="tw-flex tw-w-full tw-justify-between tw-items-center tw-py-3">
          <label class="display-profile-experience">Work Experience</label>
          <label v-if="!$route.query.email"  class="edit-btn tw-cursor-pointer" @click="handleWorkExperience()" >Add</label>
        </div>
        <v-divider/>
        <div class="tw-flex tw-w-full tw-flex-col">

          <div class="tw-flex tw-w-full tw-flex-row tw-py-5 tw-items-start" v-for="(experience,index) in
        selectedProfile.specialized_work_experience" :key="index">
            <div class="tw-flex tw-w-3/12 lg:tw-w-2/12 tw-mr-5">
              <img :src="companyLogo" height="64" width="64" alt="company logo"/>
            </div>
            <div class="tw-flex tw-w-9/12 lg:tw-w-10/12 tw-flex-col">
              <div class="tw-flex tw-w-full tw-justify-between tw-items-start">
                <label class="school-name">{{experience.employer}}</label>
                <v-menu offset-y v-if="!$route.query.email">
                  <template v-slot:activator="{ on, attrs }">
                    <vue-feather v-bind="attrs" v-on="on" type="more-horizontal" class="tw-cursor-pointer" />
                  </template>
                  <v-list>
                    <v-list-item
                        v-for="(item, index) in items" :key="index">
                      <v-list-item-title class="tw-cursor-pointer" @click="handleWorkExperience(experience.id,item)" >{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <label class="start-end-date">{{experience.job_title}},&nbsp; {{experience.job_type}}</label>
              <label v-if="experience.end_date" class="start-end-date">{{processedDay(experience.start_date)}} - {{processedDay(experience.end_date)}}</label>
              <label v-else class="start-end-date">{{processedDay(experience.start_date)}} - Till date</label>
            </div>
          </div>
        </div>

        <div id="skills" class="tw-flex tw-w-full tw-justify-between tw-items-center tw-py-3">
          <label class="display-profile-experience">Skills</label>
          <label v-if="!$route.query.email" class="edit-btn tw-cursor-pointer" @click="handleSkillsModal" >Add</label>
        </div>
        <v-divider/>
        <div class="tw-flex tw-flex-row tw-w-full tw-pb-8 tw-pt-5 tw-flex-wrap" v-if="selectedProfile.specialized_skill">
          <v-chip color="#0C8B99" text-color="white" class="tw-mr-3 tw-mt-3"
                  style="font-weight: bold;font-size: 14px;line-height: 24px;"
                  v-for="(skill,index) in selectedProfile.specialized_skill[0].skills"
                  :key="index">{{skill}}</v-chip>
        </div>
        <v-divider/>
        <div id="certificate" class="tw-flex tw-w-full tw-justify-between tw-items-center tw-py-3">
          <label class="display-profile-experience">License & Certifications</label>
          <label v-if="!$route.query.email" class="edit-btn tw-cursor-pointer" @click="handleCertificateModal">Add</label>
        </div>

        <div class="tw-flex tw-w-full tw-flex-row tw-py-5 tw-items-start" v-for="(certification,index) in selectedProfile.specialized_certification"
             :key="index+certification.title">
          <div class="tw-flex tw-w-3/12 lg:tw-w-2/12 tw-mr-5">
            <img :src="companyLogo" height="64" width="64" alt="company logo"/>
          </div>
          <div class="tw-flex tw-w-9/12 lg:tw-w-10/12 tw-flex-col">
            <div class="tw-flex tw-w-full tw-justify-between tw-items-start">
              <label class="school-name">{{certification.title}}</label>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <vue-feather v-bind="attrs" v-on="on" type="more-horizontal" class="tw-cursor-pointer" />
                </template>
                <v-list>
                  <v-list-item
                      v-for="(item, index) in items" :key="index+certification.date_awarded">
                    <v-list-item-title class="tw-cursor-pointer" @click="handleCertificateModal(certification.id,item)" >{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <label class="start-end-date">{{certification.organization}}</label>
            <label class="start-end-date">{{ processedDay(certification.date_awarded)}}</label>
          </div>
        </div>
        <v-divider/>
        <div id="accomplishment" class="tw-flex tw-w-full tw-justify-between tw-items-center tw-py-3">
          <label class="display-profile-experience">Accomplishments</label>
          <label v-if="!$route.query.email" class="edit-btn tw-cursor-pointer" @click="handleAccomplishmentsModal">Add</label>
        </div>

        <div class="tw-flex tw-w-full tw-flex-row tw-py-5 tw-items-start" v-for="(accomplishment,index) in selectedProfile.specialized_accomplishment"
             :key="index+accomplishment.date_accomplished">
          <div class="tw-flex tw-w-3/12 lg:tw-w-2/12 tw-mr-5">
            <img :src="companyLogo" height="64" width="64" alt="company logo"/>
          </div>
          <div class="tw-flex tw-w-9/12 lg:tw-w-10/12 tw-flex-col">
            <div class="tw-flex tw-w-full tw-justify-between tw-items-start">
              <label class="school-name">{{accomplishment.title}}</label>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <vue-feather v-bind="attrs" v-on="on" type="more-horizontal" class="tw-cursor-pointer" />
                </template>
                <v-list>
                  <v-list-item
                      v-for="(item, index) in items" :key="index">
                    <v-list-item-title class="tw-cursor-pointer" @click="handleAccomplishmentsModal(accomplishment.id,item)">{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <label class="start-end-date">{{accomplishment.organization}}</label>
            <label class="start-end-date">{{processedDay(accomplishment.date_accomplished)}}</label>
          </div>
        </div>
      </div>
    </div>
    <profile-modal :dialog="dialog" :section="section" @close="closeModal"
                   :data="modalData" :profileId="selectedProfile.id" @updateSpecialProfile="updateSpecialProfile" />
  </div>
</template>

<script>
import BaseButton from "../../reusables/BaseButton";
import companyLogo from "@/assets/images/company.svg"
import ProfileModal from "./ProfileModal";
import {mapGetters} from "vuex";
import moment from "moment";
export default {
  name: "SpecialProfile",
  components: {ProfileModal, BaseButton},
  props:{
    isMy : Boolean
  },
  data(){
    return{
      items:["Edit","Delete"],
      companyLogo,
      specializedProfiles:[],
      selectedProfileTitle: "",
      specializedProfileTitles:[],
      selectedProfile:{},
      tabs:["Work","Skills","licenses","Accomplishments"],
      selectedTab :"Work",
      dialog:false,
      section: "",
      modalData :{},
      workExperienceLoading : false,
      link:""
    }
  },
  computed:{
    ...mapGetters('personalProfile',['getSpecialProfiles']),
  },
  methods:{
    handleSelectedProfileTitle(profileName) {
      this.selectedProfileTitle = profileName;
       this.specializedProfiles.map(profile => {
        if (profile.job_role === profileName){
          this.selectedProfile = profile
        }
      })
    },
    handleSelectedTab(tab){
      this.selectedTab = tab
      document.getElementById(tab).scrollIntoView();
      if(tab === "Work"){
        this.link = '#experience'
      }
      else if(tab === "Accomplishments"){
        this.link = '#accomplishment'
      }
      else if(tab === "Skills"){
        this.link = '#skills'
      }
      else if (tab === "Awards"){
        this.link = '#certificate'
      }

    },
    handleUpdateSpecialProfile(){
      let data = {}
      data.special_profile_id = this.selectedProfile.id
      data.job_role = this.selectedProfile.job_role
      data.description = this.selectedProfile.description
      data.is_default = this.selectedProfile.is_default
      this.modalData = data
      this.dialog = !this.dialog
      this.section = "Edit Specialized Profile"
    },
   async handleWorkExperience(workExperienceId,item){
      if(item === 'Delete' && workExperienceId){
        let data = {}
        data.id = workExperienceId
        data.special_profile_id = this.selectedProfile.id
        console.log(data)
       await this.$store.dispatch('personalProfile/deleteWorkExperience',data)

      }
      else if (item === 'Edit' && workExperienceId){
        this.modalData = this.selectedProfile.specialized_work_experience.find(experience => experience.id === workExperienceId )
        this.dialog = !this.dialog
        this.section = "Work Experience"
      }
      else {
        this.modalData = {}
        this.dialog = !this.dialog
        this.section = "Work Experience"
      }

    },
    handleSkillsModal(){
      if (this.selectedProfile.specialized_skill.length){
        this.modalData = this.selectedProfile.specialized_skill[0]
        this.dialog = !this.dialog
        this.section = "Skills"
      }
      else {
        this.modalData = {}
        this.dialog = !this.dialog
        this.section = "Skills"
      }

    },
   async handleCertificateModal(certId, item){
      if(item === 'Delete' && certId){
        let data = {}
        data.id = certId
        data.special_profile_id = this.selectedProfile.id
        await this.$store.dispatch('personalProfile/deleteCertificateToSpecialProfile',data)

      }
      else if (item === 'Edit' && certId){
        this.modalData = this.selectedProfile.specialized_certification.find(cert => cert.id === certId )
        this.dialog = !this.dialog
        this.section = "License & Certifications"
      }
      else {
        this.modalData = {}
        this.dialog = !this.dialog
        this.section = "License & Certifications"
      }

    },
   async handleAccomplishmentsModal(accId,item){
      if(item === 'Delete' && accId){
        let data = {}
        data.id = accId
        data.special_profile_id = this.selectedProfile.id
        await this.$store.dispatch('personalProfile/deleteAccomplishmentToSpecialProfile',data)

      }
      else if (item === 'Edit' && accId){
        this.modalData = this.selectedProfile.specialized_accomplishment.find(acc => acc.id === accId )
        this.dialog = !this.dialog
        this.section = "Accomplishments"
      }
      else {
        this.modalData = {}
        this.dialog = !this.dialog
        this.section = "Accomplishments"
      }

    },
    closeModal(){
      this.dialog = false;
      this.section = ""
    },
    processedDay(date_created) {
      return moment(date_created).format("MMM YYYY");
    },
   async updateSpecialProfile(){
      this.workExperienceLoading = true
     await this.$store.dispatch('personalProfile/getAllSpecialProfiles')
     this.specializedProfiles= this.getSpecialProfiles
     this.workExperienceLoading = false
    }
  },
  async created() {
    if(this.$route.query.email){
      await this.$store.dispatch('personalProfile/adminGetAllSpecialProfiles', this.$route.query.email)
    } else {
      await this.$store.dispatch('personalProfile/getAllSpecialProfiles')
    }
    this.specializedProfiles= this.getSpecialProfiles
    this.selectedProfileTitle = this.specializedProfiles[0].job_role
    this.selectedProfile = this.specializedProfiles[0]
    this.specializedProfiles.map((profile)=>{
      this.specializedProfileTitles.push(profile.job_role)
    })
  }
}
</script>

<style scoped lang="scss">
.div-width{
  width: 500px;
  @media screen and (max-width: 1024px){
    width: 100%;
  }
}
.all-profile{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #4F4F4F;
}

.profile-card{
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  width: 85%;
}
.create-profile{
  font-family: IBM Plex Serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #475661;
}
.create-profile-desc{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #475661;
  width: 50%;
  @media screen and (max-width: 1023px){
    width: 100%;
  }
}
.create-special-default{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.create-special-selected{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #008EEF;
}
.display-profile-title{
  font-family: IBM Plex Serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1E323F;
}
.display-profile-desc{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #475661;
  word-break: break-word;

}
.display-profile-experience{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #1E323F;
}
.tab{
  background: #FFFFFF;
  border: 1px solid #C5E7FF;
  box-sizing: border-box;
  border-radius: 120px;
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #475661;
  min-width: 160px !important;
  width: max-content;
  margin-right: 1rem;
  cursor: pointer;
}
.selected-tab{
  background: #F6FCFF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 120px;
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #008EEF;
  min-width: 160px !important;
  width: max-content;
  margin-right: 1rem;
  cursor: pointer;
}
.edit-btn{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #008EEF;
  text-transform: none;
}
.school-name{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #475661;
}
.start-end-date{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.scroll {
  display: flex;
  flex-direction: row;
  max-width: 50rem;
  overflow-y: hidden;
  overflow-x: auto;
}
.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 3px;
  height: 5px;
}
.scroll::-webkit-scrollbar-thumb {
  background: transparent !important;
  max-height: 15px !important;
  border-radius: 6px;
}

.special-body {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 75vh;
}
.special-body::-webkit-scrollbar {
  background: transparent !important;
  width: 1px;
  height: 1px;
}
.special-body::-webkit-scrollbar-thumb {
  background: transparent !important;
  max-height: 20px !important;
  border-radius: 6px;
}

.scroll-y {
  display: flex;
  flex-direction: row;
  max-width: 50rem;
  overflow-y: hidden;
  overflow-x: auto;
}
.scroll-y::-webkit-scrollbar {
  background: transparent !important;
  width: 3px;
  height: 5px;
}
.scroll-y::-webkit-scrollbar-thumb {
  background: transparent !important;
  max-height: 15px !important;
  border-radius: 6px;
}
</style>
