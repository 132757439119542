<template>
  <div class="tw-flex tw-flex-col lg:tw-px-20 lg:tw-py-5 tw-pl-3  tw-py-10 tw-w-full">
    <div class="tw-flex tw-w-full tw-justify-between tw-flex-row  tw-items-center tw-my-5">
      <h6 class="profile-name">Verified Skills</h6>
      <div>
        <v-col class="opt tw-cursor-pointer tw-flex tw-gap-1" @click="$router.push({name:'Vetting'})" v-if="verifiedSkills.length !== 0">
          <svg width="24" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18456 2.99721 7.13633 4.39828 5.49707C5.79935 3.85782 7.69279 2.71538 9.79619 2.24015C11.8996 1.76491 14.1003 1.98234 16.07 2.86" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22 4L12 14.01L9 11.01" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <div>
            <span class="action-title" style="color: #FFFFFF;">Get Vetted</span>
          </div>
        </v-col>
      </div>
    </div>

    <div class="tw-flex tw-items-center tw-flex-col" v-if="verifiedSkills.length === 0">
      <h6 class="verified-text tw-w-80 tw-text-center">You do not have any Verified Skills yet, click on the Button to get Vetted</h6>
      <div class="verified-btn tw-flex tw-justify-center tw-items-center tw-my-10">
        <GetVettedButton  class="tw-w-64"/>
      </div>
    </div>

    <div class="tw-flex tw-gap-8 tw-py-5 tw-flex-col md:tw-flex-row" v-else>
      <div v-for="(skill, index) in verifiedSkills" :key=index class="badge-wrapper" @click="badgeDetail(skill.id)">
        <div class="tw-flex tw-gap-9 tw-items-center tw-justify-center">
          <h6 class="skill-text">{{skill.question_area}}</h6>
          <div class="skill-badge">
            <img :src="badge" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GetVettedButton from "../reuseables/GetVettedButton";
import {getAllListOfBadges} from "../../../services/api/APIService"

export default {
  name: "VerifiedSkills",
  components: {GetVettedButton},
  data() {
    return{
      verifiedSkills: [],
      badge: require("../../../assets/award.png"),
    }
  },
  created() {
    getAllListOfBadges().then(res => {
      this.verifiedSkills = res.data
      console.log(this.verifiedSkills, "hello")
    })
},
  methods: {
        badgeDetail(item) {
          this.$router.push({name: "enumBadge", query: {badge_id: item}})
        }
  }

}
</script>

<style scoped>
.profile-name {
  font-family: IBM Plex Serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #1E323F;
}
.verified-text {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.badge-wrapper {
  border: 1px solid #C5E7FF;
  box-shadow: 2px 2px 8px 1px rgba(0, 142, 239, 0.08);
  border-radius: 8px;
  padding: 15px 20px 15px 20px;
  cursor: pointer;
}
.badge-wrapper:hover {
  /*background: #C5E7FF;*/
  /*border: 1px solid #C5E7FF;*/
  /*box-shadow: 2px 2px 8px 1px rgba(0, 142, 239, 0.08);*/
  /*border-radius: 8px;*/
  border: 2px solid #008EEF;
}

.skill-text {
  width: 140px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #1E323F;
}
.skill-badge {
  padding: 12px 13px;
  background: rgba(197, 231, 255, 0.32);
  border-radius: 10px;
}
.opt{
  background: #008EEF;
  border: 1px solid #008EEF;
  border-radius: 8px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.action-title{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.1em;
  color: #475661;
}
</style>