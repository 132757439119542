<template>
<v-dialog v-model="dialog" persistent>
<div v-if="section === 'Edit Profile'" class="card scroll tw-flex tw-py-3 tw-px-7 tw-flex-col">
  <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-py-3">
    <h6 class="title">{{section}}</h6> <vue-feather type="x" class="tw-cursor-pointer"
                                                    @click="$emit('close')"
                                                    style="color: #475661; height: 24px; width: 24px"/>
  </div>
  <div class="tw-flex tw-w-full tw-flex-row tw-justify-between">
    <div class="tw-flex input-width tw-flex-col">
      <label class="label tw-mb-3">First name</label>
      <v-text-field v-model="data.first_name"
                    class="label" solo hide-details />
    </div>
    <div class="tw-flex input-width tw-flex-col">
      <label class="label tw-mb-3">Last name</label>
      <v-text-field class="label" v-model="data.last_name" solo hide-details/>
    </div>
  </div>
  <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
    <label class="label tw-mb-3">Location</label>
    <v-combobox   class="label" v-model="data.location" solo hide-details :items="countryStates"/>
  </div>
  <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
    <label class="label ">Job Status</label>
    <div class="tw-flex tw-w-full tw-flex-row tw-flex-wrap ">
      <div class="tw-py-3 tw-px-5 tw-mr-5 tw-mt-5 tw-text-capitalize tw-cursor-pointer" @click="handleSelectedStatus(status)"
           :class="status.split(' ').join('_') === data.job_search_stage? 'selected-status':'job-status'"
           v-for="(status,index) in jobStatus" :key="index">{{status}}</div>
    </div>
  </div>
  <div class="tw-flex tw-mt-5 tw-w-full tw-items-center tw-justify-end tw-py-5">
    <base-button class="tw-mr-3" button-text="Cancel" width="auto" outlined @click="$emit('close')" />
    <base-button width="85px" button-text="Save" :loading="loading" @click="updateUserProfile" />
  </div>
</div>
  <div v-if="section === 'Edit Link'" class="card tw-flex tw-py-3 tw-px-7 tw-flex-col">
    <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-py-3">
      <h6 class="title">{{section}}</h6> <vue-feather type="x" class="tw-cursor-pointer"
                                                      @click="$emit('close')"
                                                      style="color: #475661; height: 24px; width: 24px"/>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
      <label class="label tw-mb-3">Title</label>
      <v-text-field v-model="link.title" placeholder="LinkedIn"   class="label" solo hide-details/>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
      <label class="label tw-mb-3">Link</label>
      <v-text-field placeholder="https://www.linkedin.com/in/semicolon" @change="validateLink" v-model="link.link"  class="label" solo hide-details/>
    </div>
    <div class="tw-flex tw-w-full tw-justify-between tw-mt-5 tw-items-center">
      <v-btn text class="edit-btn" @click="switchToNewSkill">Add New</v-btn>
      <div class="tw-flex tw-w-auto tw-items-center tw-justify-end tw-py-5">
        <base-button class="tw-mr-3" button-text="Cancel" width="auto" outlined @click="$emit('close')" />
        <base-button width="85px" button-text="Save" @click="saveLink" />
      </div>
    </div>
  </div>

  <div v-if="section === 'Add Link'" class="card tw-flex tw-py-3 tw-px-7 tw-flex-col">
    <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-py-3">
      <h6 class="title">{{section}}</h6> <vue-feather type="x" class="tw-cursor-pointer"
                                                      @click="$emit('close')"
                                                      style="color: #475661; height: 24px; width: 24px"/>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
      <label class="label tw-mb-3">Title</label>
      <v-text-field v-model="link.title" placeholder="LinkedIn" class="label" solo hide-details/>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
      <label class="label tw-mb-3">Link</label>
      <v-text-field placeholder="www.linkedin.com/in/semicolon" @change="validateLink" v-model="link.link"   class="label" solo hide-details/>
    </div>
      <div class="tw-flex tw-w-auto tw-items-center tw-justify-end tw-py-5 tw-mt-5">
        <base-button class="tw-mr-3" button-text="Cancel" width="auto" outlined @click="$emit('close')" />
        <base-button width="85px" button-text="Save" @click="saveLink" :loading="loading" />
      </div>
  </div>

  <div v-if="section === 'Education'" class="card scroll tw-flex tw-py-3 tw-px-7 tw-flex-col">
    <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-py-3">
      <h6 class="title">{{section}}</h6> <vue-feather type="x" class="tw-cursor-pointer"
                                                      @click="$emit('close')"
                                                      style="color: #475661; height: 24px; width: 24px"/>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
      <label class="label tw-mb-3">Institute/Training</label>
      <v-combobox v-model="education.school" :items="schools" placeholder="University of Lagos"  class="label" solo hide-details/>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
      <label class="label tw-mb-3">Degree/Certificate</label>
      <v-combobox v-model="education.certificate" :items="certifications" placeholder="BSC"  class="label" solo hide-details/>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
      <label class="label tw-mb-3">Program/Course</label>
      <v-combobox v-model="education.course" :items="courses" placeholder="Computer Science"  class="label" solo hide-details/>
    </div>
    <div class="tw-flex tw-w-full tw-flex-row tw-justify-between tw-py-5">
      <div class="tw-flex input-width tw-flex-col">
        <label class="label tw-mb-3">Start Date</label>
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="education.startDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="education.startDate"
                class="label" solo hide-details
                append-icon="mdi-calendar"
                placeholder="MM/YY"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="education.startDate"
              :max="education.endDate?education.endDate:maxDate"
              no-title
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.menu.save(education.startDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
      <div class="tw-flex input-width tw-flex-col" v-if="!education.still_enrolled">
        <label class="label tw-mb-3">End Date</label>
        <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="education.endDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="education.endDate"
                class="label" solo hide-details
                append-icon="mdi-calendar"
                placeholder="MM/YY"
                readonly
                v-bind="attrs"
                v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker
              v-model="education.endDate"
              :max="maxDate"
              :min="education.startDate"
              no-title
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu2 = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.menu2.save(education.endDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    </div>

    <div class="tw-flex tw-w-full tw-items-end">
      <v-checkbox class="label" color="#8F47F4" hide-details  v-model="education.still_enrolled" label="I’m still enrolled in this program" />
    </div>

    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
      <label class="label tw-mb-3">Description</label>
      <v-textarea v-model="education.description" :rules="rules"  class="label" solo  rows="3" counter
                  :maxlength="maxlength" >
      </v-textarea>
    </div>

    <div class="tw-flex tw-w-full tw-items-center tw-justify-end tw-py-5 tw-mt-5">
      <base-button class="tw-mr-3" button-text="Cancel" width="auto" outlined @click="$emit('close')" />
      <base-button width="85px" button-text="Save" :loading="loading" @click="saveEducation()" />
    </div>
  </div>

  <div v-if="section === 'About Me'" class="card tw-flex tw-py-3 tw-px-7 tw-flex-col">
    <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-py-3">
      <h6 class="title">{{section}}</h6> <vue-feather type="x" class="tw-cursor-pointer"
                                                      @click="$emit('close')"
                                                      style="color: #475661; height: 24px; width: 24px"/>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
      <v-textarea
          v-model="data.about_me"
          counter
          :maxlength="maxlength"
          :rules="rules"
          solo
          rows="4"
      >
      </v-textarea>
    </div>

    <div class="tw-flex tw-w-auto tw-items-center tw-justify-end tw-py-5">
      <base-button class="tw-mr-3" button-text="Cancel" width="auto" outlined @click="$emit('close')" />
      <base-button width="85px" button-text="Save" :loading="loading" @click="updateAboutMeUserProfile" />
    </div>
  </div>

  <div v-if="section === 'Skills'" class="card tw-flex tw-py-3 tw-px-7 tw-flex-col">
    <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-py-3">
      <h6 class="title">{{section}}</h6> <vue-feather type="x" class="tw-cursor-pointer"
                                                      @click="$emit('close')"
                                                      style="color: #475661; height: 24px; width: 24px"/>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5 skills">
      <v-combobox
          v-model="skills"
          color="linear-gradient(0deg, #FFFFFF, #FFFFFF)"
          label="Skills"
          hide-details
          solo
          height="auto"
          multiple
          required
          chips

      >
        <template v-slot:selection="data">
          <v-chip
              v-bind="data.attrs"
              :input-value="data.skills"
              class="tw-my-5"
              color="#0C8B99"
              text-color="white"
              @click="data.select"
              id="Skill">
           <div class="tw-flex tw-items-center tw-w-full">
             <label class=""> {{ data.item }}</label>
             <div @click="handleRemoveSkill(data.item)">
               <vue-feather type="x" style="display: flex !important;" class="tw-w-4 tw-ml-2 tw-self-center"/>
             </div>
           </div>
          </v-chip>
        </template>
      </v-combobox>
    </div>
    <small class="tip">Note: press the Enter button after entering a skill</small>
    <div class="tw-flex tw-w-auto tw-items-center tw-justify-end tw-py-5">
      <base-button class="tw-mr-3" button-text="Cancel" width="auto" outlined @click="$emit('close')" />
      <base-button width="85px" button-text="Save" :loading="loading" @click="updateSkills" />
    </div>
  </div>
  <div v-if="section === 'Edit Specialized Profile'" class="card tw-flex tw-py-3 tw-px-7 tw-flex-col">
    <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-py-3">
      <h6 class="title">{{section}}</h6> <vue-feather type="x" class="tw-cursor-pointer"
                                                      @click="$emit('close')"
                                                      style="color: #475661; height: 24px; width: 24px"/>
    </div>
    <validation-observer ref="observe" v-slot="{handleSubmit}">
      <form @submit.prevent="handleSubmit(updateSpecializeProfile)">
        <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
          <label class="label tw-mb-3">Title <span style="color: red">*</span></label>
          <validation-provider v-slot="{errors}" name="Title" rules="required">
            <v-text-field v-model="data.job_role" id="Title" placeholder="Backend Engineer"  class="label" solo hide-details/>
            <span class="err" >{{ errors[0] }}</span>
          </validation-provider>
        </div>
        <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
          <label class="label tw-mb-3">Description</label>
          <v-textarea v-model="data.description"  class="label" solo :rules="rules" rows="2" counter
                      :maxlength="maxlength"/>
        </div>
        <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
          <v-checkbox hide-details  v-model="data.is_default" color="#8F47F4" >
            <template #label>
              <label class="label tw-cursor-pointer">Make Default Profile</label>
            </template>
          </v-checkbox>
        </div>
        <div class="tw-flex tw-w-full tw-items-center tw-justify-end tw-py-5 tw-mt-5">
          <base-button class="tw-mr-3" button-text="Cancel" width="auto" outlined @click="$emit('close')" />
          <base-button width="85px" button-text="Save" type="submit" :loading="loading" />
        </div>
      </form>
    </validation-observer>
  </div>
  <div v-if="section === 'Work Experience'" class="card tw-flex tw-py-3 tw-px-7 tw-flex-col">
    <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-py-3">
      <h6 class="title">{{section}}</h6> <vue-feather type="x" class="tw-cursor-pointer"
                                                      @click="$emit('close')"
                                                      style="color: #475661; height: 24px; width: 24px"/>
    </div>
    <validation-observer ref="observe" v-slot="{handleSubmit}">
      <form @submit.prevent="handleSubmit(workExperienceAction)">
        <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
          <label class="label tw-mb-3">Employer/Company <span style="color: red">*</span></label>
          <validation-provider v-slot="{errors}" name="Employer/Company" rules="required">
            <v-text-field v-model="workExperience.employer" id="Employer/Company" placeholder="Semicolon Africa"  class="label" solo hide-details/>
            <span class="err" >{{ errors[0] }}</span>
          </validation-provider>
        </div>
    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
      <label class="label tw-mb-3">Job Title <span style="color: red">*</span></label>
      <validation-provider v-slot="{errors}" name="Job Title" rules="required">
      <v-text-field id="Job Title" placeholder="Job Title" v-model="workExperience.job_title"  class="label" solo hide-details/>
        <span class="err" >{{ errors[0] }}</span>
      </validation-provider>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
      <label class="label tw-mb-3">Employment Type </label>
      <validation-provider v-slot="{errors}" name="Employment Type " rules="required">
      <v-combobox :items="employmentTypes" v-model="workExperience.job_type"
                  id="Employment Type " placeholder="Internship"
                  class="label" solo hide-details />
        <span class="err" >{{ errors[0] }}</span>
      </validation-provider>
    </div>

    <div class="tw-flex tw-w-full tw-flex-row tw-justify-between tw-py-5">
      <div class="tw-flex input-width tw-flex-col">
        <label class="label tw-mb-3">Start Date  <span style="color: red">*</span></label>
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="workExperience.start_date"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <validation-provider v-slot="{errors}" name="Start Date " rules="required">
            <v-text-field
                id="Start Date"
                v-model="workExperience.start_date"
                class="label" solo hide-details
                append-icon="mdi-calendar"
                placeholder="MM/YY"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
              <span class="err" >{{ errors[0] }}</span>
            </validation-provider>
          </template>
          <v-date-picker
              v-model="workExperience.start_date"
              :max="workExperience.end_date?workExperience.end_date:maxDate"
              no-title
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.menu.save(workExperience.start_date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
      <div class="tw-flex input-width tw-flex-col" v-if="!workExperience.currently_work_here">
        <label class="label tw-mb-3">End Date <span style="color: red">*</span></label>
        <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="workExperience.end_date"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <validation-provider v-slot="{errors}" name="End Date" :rules="!workExperience.currently_work_here ? 'required':''">
            <v-text-field
                id="End Date"
                v-model="workExperience.end_date"
                class="label" solo hide-details
                append-icon="mdi-calendar"
                placeholder="MM/YY"
                readonly
                v-bind="attrs"
                v-on="on"
            >
            </v-text-field>
              <span class="err" >{{ errors[0] }}</span>
            </validation-provider>
          </template>
          <v-date-picker
              v-model="workExperience.end_date"
              :max="maxDate"
              :min="workExperience.start_date"
              no-title
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu2 = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.menu2.save(workExperience.end_date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    </div>

    <div class="tw-flex tw-w-full tw-items-end">
      <v-checkbox  color="#8F47F4" hide-details v-model="workExperience.currently_work_here" >
        <template #label>
          <label class="label tw-cursor-pointer">I still work here</label>
        </template>
      </v-checkbox>

    </div>

    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
      <label class="label tw-mb-3">Description</label>
      <v-textarea v-model="workExperience.description"  class="label" solo :rules="rules" rows="3" counter
                  :maxlength="maxlength" />
    </div>

    <div class="tw-flex tw-w-full tw-items-center tw-justify-end tw-py-5 tw-mt-5">
      <base-button class="tw-mr-3" button-text="Cancel" width="auto" outlined @click="$emit('close')" />
      <base-button width="85px" button-text="Save" type="submit" :loading="loading" />
    </div>
      </form>
    </validation-observer>
  </div>


  <div v-if="section === 'License & Certifications'" class="card tw-flex tw-py-3 tw-px-7 tw-flex-col">
    <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-py-3">
      <h6 class="title">{{section}}</h6> <vue-feather type="x" class="tw-cursor-pointer"
                                                      @click="$emit('close')"
                                                      style="color: #475661; height: 24px; width: 24px"/>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
      <label class="label tw-mb-3">Title</label>
      <v-text-field v-model="certificate.title" placeholder="Chief Architect"  class="label" solo hide-details/>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
      <label class="label tw-mb-3">Description</label>
      <v-textarea v-model="certificate.description"  class="label" solo :rules="rules" rows="3" counter
                  :maxlength="maxlength"/>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
      <label class="label tw-mb-3">Organization</label>
      <v-text-field v-model="certificate.organization" placeholder="Institute of Chartered Accountant"  class="label" solo hide-details/>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
      <label class="label tw-mb-3">Credential ID (Optional)</label>
      <v-text-field v-model="certificate.certificate_id" placeholder="Institute of Chartered Accountant"  class="label" solo hide-details/>
    </div>

      <div class="tw-flex tw-w-full tw-flex-col tw-py-5">
        <label class="label tw-mb-3">Date achieved</label>
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="certificate.date_awarded"
            transition="scale-transition"
            offset-y
            min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="certificate.date_awarded"
                class="label" solo hide-details
                append-icon="mdi-calendar"
                placeholder="MM/YY"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="certificate.date_awarded"
              :max="maxDate"
              no-title
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.menu.save(certificate.date_awarded)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    <div class="tw-flex tw-w-full tw-items-center tw-justify-end tw-py-5 tw-mt-5">
      <base-button class="tw-mr-3" button-text="Cancel" width="auto" outlined @click="$emit('close')" />
      <base-button width="85px" button-text="Save" @click="certificateAction" />
    </div>
  </div>

  <div v-if="section === 'Accomplishments'" class="card tw-flex tw-py-3 tw-px-7 tw-flex-col">
    <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-py-3">
      <h6 class="title">{{section}}</h6> <vue-feather type="x" class="tw-cursor-pointer"
                                                      @click="$emit('close')"
                                                      style="color: #475661; height: 24px; width: 24px"/>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
      <label class="label tw-mb-3">Title</label>
      <v-text-field v-model="accomplishment.title" placeholder="Chief Architect"  class="label" solo hide-details/>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
      <label class="label tw-mb-3">Description</label>
      <v-textarea v-model="accomplishment.description"   class="label" solo :rules="rules" rows="3" counter
                  :maxlength="maxlength"/>
    </div>

    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
      <label class="label tw-mb-3">Category </label>
      <v-combobox :items="accomplishmentsItems" v-model="accomplishment.category" placeholder="Award"  class="label" solo hide-details/>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5">
      <label class="label tw-mb-3">Organization</label>
      <v-text-field v-model="accomplishment.organization" placeholder="Institute of Chartered Accountant"  class="label" solo hide-details/>
    </div>

    <div class="tw-flex tw-w-full tw-flex-col tw-py-5">
      <label class="label tw-mb-3">Date achieved</label>
      <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="accomplishment.date_accomplished"
          transition="scale-transition"
          offset-y
          min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="accomplishment.date_accomplished"
              class="label" solo hide-details
              append-icon="mdi-calendar"
              placeholder="MM/YY"
              readonly
              v-bind="attrs"
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="accomplishment.date_accomplished"
            :max="maxDate"
            no-title
            scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
              text
              color="primary"
              @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              @click="$refs.menu.save(accomplishment.date_accomplished)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <div class="tw-flex tw-w-full tw-items-center tw-justify-end tw-py-5 tw-mt-5">
      <base-button class="tw-mr-3" button-text="Cancel" width="auto" outlined @click="$emit('close')" />
      <base-button width="85px" button-text="Save" :loading="loading" @click="accomplishmentAction" />
    </div>
  </div>
</v-dialog>
</template>

<script>
import BaseButton from "../../reusables/BaseButton";
import {getAllCountries} from "../../../services/api/APIService";
import school from "@/assets/schools.js"
import course from "@/assets/courses.js"
import moment from "moment";
export default {
  name: "ProfileModal",
  components: {BaseButton},
  props:{
    section:[String],
    dialog : Boolean,
    data: Object,
    profileId : Number
  },
  data(){
    return{
      jobStatus: ['actively looking', 'not looking but open to offer', 'closed to offers'],
      selectedJobStatus : "",
      maxDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      startDate:null,
      endDate : null,
      menu2: false,
      rules: [v => v.length <= 150 || 'Max 150 characters'],
      aboutMe: "about you",
      skills:[],
      loading : false,
      countryStates:[],
      schools: school.school,
      courses: course.course,
      certifications:["JSSCE","GCE","WAEC","NECO","OND","HND","BSC","MSC","PHD"],
      accomplishmentsItems:["Publication", "Patent", "Course", "Honor and Award", "Project", "Scholarship", "Award", "Others"],
      employmentTypes:["Full-Time","Part-Time","Contract","Internship","Flexible"],
      education:{
        still_enrolled: false
      },
      link:{},
      workExperience:{
        currently_work_here : false
      },
      accomplishment:{},
      certificate:{},
      hint:"This field cannot be more than 200 words",
      maxlength:200
    }
  },
  watch: {
    'education.still_enrolled': {
      handler: function (value) {
        if (value) {
          this.education.endDate = ""
        }
      }
    },
      'workExperience.currently_work_here': {
        handler: function (value) {
          if (value) {
            this.workExperience.end_date = ""
          }
        },
        immediate:true
      },

    'data.id':{
      handler: function (val){
        if (val){
          this.education.startDate = this.data.start_date
          this.education.school = this.data.school_or_training
          this.education.still_enrolled = this.data.still_enrolled
          this.education.endDate = this.data.end_date
          this.education.description = this.data.description
          this.education.certificate = this.data.degree_or_certificate
          this.education.id = this.data.id
          this.education.course = this.data.program_or_course
          this.workExperience = this.data
          this.accomplishment = this.data
          this.certificate = this.data
          this.skills = this.data.skills

        }
        else {
          this.education = {}
          this.workExperience ={}
          this.accomplishment ={}
          this.certificate ={}
          this.skills = []
        }
      },
      deep : true,
      immediate: true
    },
    'data' :{
      handler: function (val){
        if (val){
          this.link ={}
          this.link.id = this.data.id
          this.link.title = this.data.title
          this.link.link = this.data.link
        }
      },
      deep : true,
      immediate: true
    },

  },
  methods:{
    toggleCurrentlyWorkHere(){
      if(this.workExperience.currently_work_here){
        this.workExperience.currently_work_here = ""
      }
    },
    handleRemoveSkill(skill){
      this.skills= this.skills.filter(item => item !== skill)
    },

   async certificateAction(){
     this.loading = true
     this.certificate.special_profile_id = this.profileId
     if (this.certificate.id){
        await this.$store.dispatch('personalProfile/updateCertificateToSpecialProfile',this.certificate)
       this.$emit('updateSpecialProfile')
       this.loading = false
       this.$emit('close')
     }
     else {
        await this.$store.dispatch('personalProfile/addCertificateToSpecialProfile', this.certificate)
       this.$emit('updateSpecialProfile')
       this.loading = false
       this.$emit('close')
     }
    },
    async accomplishmentAction(){
      this.loading = true
      this.accomplishment.special_profile_id = this.profileId
      if (this.accomplishment.id){
        await this.$store.dispatch('personalProfile/updateAccomplishmentToSpecialProfile',this.accomplishment)
        this.$emit('updateSpecialProfile')
        this.loading = false
        this.$emit('close')
      }
      else {
        await this.$store.dispatch('personalProfile/addAccomplishmentToSpecialProfile', this.accomplishment)
        this.$emit('updateSpecialProfile')
        this.loading = false
        this.$emit('close')
      }
    },
    async updateSkills(){
      this.loading = true
      let mySkills = {}
      mySkills.special_profile_id = this.profileId
      mySkills.id = this.data.id
      mySkills.skills = this.skills
      let arraySkillS = []
      arraySkillS.push(mySkills)
      this.workExperience.special_profile_id = this.profileId
      if (mySkills.id){
        this.$store.dispatch('personalProfile/updateSkillsToSpecialProfile',mySkills).then(() =>{
          this.$emit('updateSpecialProfile')
          this.loading = false
          this.$emit('close')
        })
            .catch(err=>{
              let errorKey = Object.keys(err.response.data)
              this.loading = false
              this.$displaySnackbar({
                message: err.response.data[errorKey[0]],
                success: false
              })
            })

      }
    },
    async updateSpecializeProfile(){
      this.loading = true
     await this.$store.dispatch('personalProfile/updateSpecializeProfile', this.data).then(()=>{
       this.$emit('updateSpecialProfile')
       this.loading = false
       this.$emit('close')
     })
         .catch(err=>{
           let errorKey = Object.keys(err.response.data)
           this.loading = false
           this.$displaySnackbar({
             message: err.response.data[errorKey[0]],
             success: false
           })
         })
    },
   async workExperienceAction(){
      this.loading = true
    this.workExperience.special_profile_id = this.profileId
      if (this.workExperience.id){
        this.$store.dispatch('personalProfile/updateWorkExperience',this.workExperience).then(() =>{
          this.$emit('updateSpecialProfile')
          this.loading = false
          this.$emit('close')
        })
            .catch(err=>{
              let errorKey = Object.keys(err.response.data)
              this.loading = false
              this.$displaySnackbar({
                message: err.response.data[errorKey[0]],
                success: false
              })
            })

      }
      else {
        if (this.workExperience.currently_work_here){
          delete this.workExperience.end_date
        }
        this.$store.dispatch('personalProfile/addWorkExperience',this.workExperience).then(() =>{
          this.$emit('updateSpecialProfile')
          this.loading = false
          this.$emit('close')
        })
            .catch(err=>{
              let errorKey = Object.keys(err.response.data)
              this.loading = false
             this.$displaySnackbar({
                message: err.response.data[errorKey[0]],
                success: false
              })
            })
      }
    },
    handleSelectedStatus(status){
      this.data.job_search_stage = status.split(' ').join('_')
    },
    updateUserProfile(){
      this.loading = true
      this.$store.dispatch('applicantProfile/updateUserProfile',this.data).then(() =>{
        this.loading = false
        this.$emit('close')
      })
          .catch(err => {

            let errorKey = Object.keys(err.response.data)
            this.$displaySnackbar({
              message: err.response.data[errorKey[0]],
              success: false
            })
          })
    },
    updateAboutMeUserProfile(){
      this.loading = true
      this.$store.dispatch('applicantProfile/updateUserProfile',{about_me:this.data.about_me}).then(() =>{
        this.loading = false
        this.$emit('close')
      })
          .catch(err => {
            let errorKey = Object.keys(err.response.data)
            this.$displaySnackbar({
              message: err.response.data[errorKey[0]],
              success: false
            })
          })
    },
    getCountries(){
      getAllCountries().then(res =>{
        res.data.data.map((country) =>{
          country.states.map((state) =>{
            this.countryStates.push(state.name + ", "  + country.name)
          })
        })
      })
          .catch(err => {
            let errorKey = Object.keys(err.response.data)
            this.loading = false
            this.$displaySnackbar({
              message: err.response.data[errorKey[0]],
              success: false
            })
          })
    },
    toggleStillEnrolled(){
      this.education.still_enrolled = !this.education.still_enrolled
      if (this.education.still_enrolled){
        this.education.endDate = ""
      }
    },
    saveEducation(){
      this.loading = true
      const data = {}
      data.id = this.education.id
      data.school_or_training = this.education.school
      data.degree_or_certificate = this.education.certificate
      data.program_or_course = this.education.course
      data.start_date = this.education.startDate
      if (this.education.endDate){
        data.end_date = this.education.endDate
      }
      data.still_enrolled = this.education.still_enrolled
      data.description = this.education.description
      if (this.checkData()){
        if (this.education.still_enrolled){
          this.education.endDate = ""
        }
        if (this.education.id){
          this.$store.dispatch('personalProfile/updateEducation',data).then(()=>{
            this.loading = false
            this.$emit('refresh')
            this.$emit('close')
          })
              .catch(()=>{
                this.loading = false
                })

        }
        else {
          this.$store.dispatch('personalProfile/addEducation',data).then(()=>{
            this.loading = false
            this.$emit('refresh')
            this.$emit('close')
          })
              .catch(()=>{
                this.loading = false
              })
        }
      }

    },
    checkData(){
      let check = false
      if (!this.education.school){
        this.$displaySnackbar({message:"Institute/Training can not be null", success : false})
      }
      else if (!this.education.certificate ){
        this.$displaySnackbar({message:"Degree/certificate can not be null", success : false})
      }
      else if (!this.education.course){
        this.$displaySnackbar({message:"Program/Course can not be null", success : false})
      }
      else if (!this.education.startDate ){
        this.$displaySnackbar({message:"Program Start Date can not be null", success : false})
      }
      else if (this.education.endDate !== null && moment(this.education.endDate).isBefore(this.education.startDate)){
        this.$displaySnackbar({message:"Program End Date can not be earlier", success : false})
      }
      else {
        check = true
      }
      return check
    },
    saveLink(){
      this.loading = true
      if (this.link.title === null || this.link.title === undefined){
        this.loading = false
        return this.$displaySnackbar({message:"Link title can not be null", success: false})
      }
      else if (this.validateLink() && !this.link.id) {
        this.$store.dispatch('personalProfile/addLink',this.link).then(()=>{
          this.loading = false
          this.$emit('allLinks')
          this.$emit('close')
        }).catch(() =>{
          this.loading = false
        })
      }
      else if (this.validateLink() && this.link.id ) {

        this.$store.dispatch('personalProfile/updateLink',this.link).then(()=>{
          this.loading = false
          this.$emit('allLinks')
          this.$emit('close')
        }).catch(() =>{
          this.loading = false
        })
      }
      else {
        this.loading = false
        return this.$displaySnackbar({message:"Not a valid link", success: false})
      }
    },
    validateLink(){
      let valid = false
      const  re = '((http|https)://)?' + '[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]' + '{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
      let reg = new RegExp(re)
      if (this.link.link.startsWith('http') && this.link.link.match(reg)){
        valid = true
      }
      else  if (this.link.link.startsWith('www')){
        this.link.link = "https://"+this.link.link
        if (this.link.link.match(reg)){
          valid = true
        }
      }
      return valid
    },
    switchToNewSkill(){
      this.link = {}
      this.$emit('changeSection','Add Link')
    }
  },
  mounted() {
    this.getCountries()
    if (this.data){
      this.aboutMe =  this.data
    }

  },

}
</script>

<style scoped lang="scss">

.card{
  background: #FFFFFF;
  box-shadow: 0px 24px 32px rgba(0, 61, 103, 0.16), 0px 4px 4px rgba(0, 61, 103, 0.1);
  border-radius: 12px;
}
.title{
  font-family: IBM Plex Serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #2B1C1C;
}
.label{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}
.edit-btn{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #008EEF;
  text-transform: none;
}

.selected-status{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #008EEF;
  text-transform: capitalize;
  border: 1.5px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
}

.input-width{
  width: 48%;
}
.tip{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}
.job-status{
  border: 1.5px solid #9CABB5;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
  text-transform:capitalize
}
::v-deep .v-dialog {
  width: 442px!important;
  max-height: 90vh !important;
  overflow-x: hidden;
  overflow-y: auto;
  @media screen and (max-width: 1023px){
    width: 100% !important;
    max-width: 100%!important;
    margin: 12px!important;

  }
}
::v-deep .v-dialog::-webkit-scrollbar {
  background: transparent !important;
  width: 3px;
  height: 5px;
}
::v-deep .v-dialog::-webkit-scrollbar-thumb {
  background: transparent !important;
  max-height: 15px !important;
  border-radius: 6px;
}
::v-deep .v-messages{
  display: none !important;
}
.v-counter {
  margin-left: -12px !important;
}

.skills ::v-deep .v-text-field.v-text-field--solo .v-input__append-inner, .v-text-field.v-text-field--solo .v-input__prepend-inner {
  display: none !important;
}
.v-select .v-chip {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.err{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: red;
}
.scroll {
  //max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 3px;
  height: 5px;
}
.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 15px !important;
  border-radius: 6px;
}
</style>
